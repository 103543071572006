.App {
    text-align: center;
    vertical-align: top;
    padding: 0rem;
}

@media (min-width: 600px) {
  .blocks {
      grid-template-columns: repeat(2, 1fr);
  }
}
.blocks {
  margin: 0 auto;
  display: grid;
  padding: 0rem;
  font-size: 30px;
}
.block {
    display: grid;
    background-color: orange;
    grid-template-columns: repeat(2, 1fr);    
    color: white;
    padding: 1rem;
    margin: 1rem;    
}
.blockheader {
    background-color: orangered;    
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;    
}
.icon1 {
    grid-column-start: 1;
    grid-row-start: 2;
    grid-row-end: 3;
}
.icon2 {
    grid-column-start: 1;
    grid-row-start: 3;
    grid-row-end: 4;
}
.icon3 {
  grid-column-start: 1;
  grid-row-start: 4;
  grid-row-end: 5;
}
.text1 {
    grid-column-start: 2;
    grid-row-start: 2;
    grid-row-end: 3;
}
.text2 {
  grid-column-start: 2;
  grid-row-start: 3;
  grid-row-end: 4;
  font-size: 14px;
}
.text3 {
    grid-column-start: 2;
    grid-row-start: 3;
    grid-row-end: 4;
}
.text4 {
  grid-column-start: 2;
  grid-row-start: 4;
  grid-row-end: 5;
}

.App-header {
  background-color: #282c34;  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  
  color: white;
}
.App-chart {
    background-color: #bec6d6;
    color: white;    
  }

.table {
  font-size: 10px;
  border: 1px;
  margin: 5px;
}
.table th {
  font-style: oblique;
}

.App-link {
  color: #61dafb;
}
